<template>
   <div class="my-template-product">
     <div class="tab-page-header">
        <div>
          <div class="tab-page-title">내 템플릿</div>
          <div class="page-desc">내 템플릿은  '플랫폼 템플릿 > 스킨'에 노출됩니다.</div>
        </div>
        <router-link to="/template_create">
          <button class="button is-primary button-create"><i class="material-icons">add</i> 템플릿 등록</button>
        </router-link>
     </div>
     <div class="tabs">
      <div class="tab unselect" v-for="(tab, index) in tabs" :key="index" 
      :class="{ active: tab.value === selectedTab }" @click="clickTab(tab.value)">{{ tab.label }}</div>
    </div>

    <div class="template-list">
      <template v-if="list.length > 0">
        <div class="template-count">전체 {{ page.count }}개</div>
        <div class="template-item">
          <CardTemplateProduct v-for="(item, index) in list" :key="`card-${index}`" 
            :card="item" @delete="deleteProduct(item)" />
        </div>
        <lp-paginate v-if="page.total_page > 1" :filtered="page" @getData="getData" />
      </template>
      <template v-else>
        <div class="template-empty">
          아직 등록된 템플릿이 없습니다.
        </div>
      </template>
    </div>
   </div>
</template>
<script>
import CardTemplateProduct from '@/components/component/CardTemplateProduct.vue'
import LpPaginate from '@/components/component/LpPaginate.vue'
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin'
import UserAPIMixin from '@/mixins/UserAPIMixin'
export default {
   name: 'MyTemplateProduct',
   mixins: [
    PartnerAPIMixin,
    UserAPIMixin
   ],
   components: {
     CardTemplateProduct,
     LpPaginate
   },
   created() {
      this.getData();
   },
   data() {
     return {
       tabs: [
        {
          label: '전체',
          value: ''
        },
        {
          label: '전시중',
          value: 4
        },
        {
          label: '심사중',
          value: 0
        },
        {
          label: '심사반려',
          value: 2
        }
       ],
       selectedTab: '',
       list: [],
       page: {
        cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 10,
          count: 0
       }
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     getData() {
      let url = 'product/mine';
      let params = {
        ...this.page,
      }
      if (this.selectedTab!=='') {
        if(this.selectedTab === 4) {
          params.prod_type = 0;
        } else {
          params.request_status = this.selectedTab;
          params.prod_type = "2,3";
        }
      }
      this.request.partner.get(url, {params: params}).then((res) => {
         this.list = res.data.data;
         this.page.count = res.data.count;
         this.page.cur_page = res.data.cur_page;
         this.page.page_length = res.data.page_length;
         this.page.total_page = res.data.total_page;
      })
     },
     clickTab(value) {
      this.selectedTab = value;
      this.getData();
     },
     deleteProduct(item) {
      if(confirm('삭제하시겠습니까?')) {
        this.request.user.delete(`product/${item.id}`).then(() => {
          this.toast('삭제되었습니다.');
          this.page.cur_page = 1;
          this.getData();
        })
      }
     }
   }
};
</script>
<style lang='stylus' scoped>
@import '~assets/css/lp_main'
.my-template-product
  .tab-page-header
    display flex
    align-items center
    justify-content space-between
    padding 40px 0 20px 0
    .tab-page-title
      font-size 24px
      font-weight 700
      color $main
    .page-desc
      font-size 14px
      color $sub3
      margin-top 8px

  .tabs
    display flex
    align-items center
    gap 24px
    border-bottom 1px solid $gray
    .tab
      font-size 16px
      font-weight 700
      color $sub3
      cursor pointer
      padding 8px 0 6px 0
      border-bottom 2px solid transparent
      &.active
        color $main
        font-weight 700
        border-bottom 2px solid $main

  .button-create
    display flex
    align-items center
    justify-content center
    gap 8px
    font-size 16px
    padding 16px 24px
    box-shadow 0px 4px 9px 0px rgba(0, 0, 0, 0.10)
    i
      font-size 20px


  .template-list
    margin-top 24px
    padding 40px
    border-radius 12px
    background-color white
    .template-count
      font-size 14px
      color $sub
      margin-bottom 16px

    .template-item
      display flex
      flex-direction column
      gap 16px
      margin-bottom 12px

  .template-empty
    font-size 14px
    color $sub3
    text-align center
    margin 56px 0
@media screen and (max-width: 1024px)
  .my-template-product
    padding 0 16px
    .tab-page-header
      padding 24px 0 16px 0
      display block
      .tab-page-title
        font-size 18px
    .button-create
      margin-top 12px
      width 100%
      height 48px
    .tabs
      .tab
        font-size 14px

    .template-list
      padding 0
      background-color transparent

</style>